.lander-container {
  /* background-repeat: no-repeat; */
  background-image: url('../assets/CashBTN-bg.png');
  /* background-attachment: fixed; */
  background-size: cover;
  min-height: 80vh;
  padding: 20px;
  margin: 0%;
}

.cta {
  top: 5rem;
  left: 2rem;
  background-color: rgba(137, 43, 226, 0.342);
  max-width: 420px;
  padding: 40px;
  position: relative;
}

.plugins-container {
  min-height: 400px;
  background-color: rgba(137, 43, 226, 0.342);
  padding: 40px;
}

#navHeader {
  position: fixed;
  color: whitesmoke;
}

.tips-card {
  min-height: 309px;
}
